import './audioSwitch.css'
import React, {useEffect} from "react";


export const AudioSwitch = (props) => {

    useEffect(()=> {
        if (props.playAudio) {
            props.audio.play()
        }
        if (!props.playAudio) {
            props.audio.pause()
        }
    }, [props.playAudio])

    const onMouseOver = () => {
        props.setCursorState((state) => state = 'cursor-hover');
    };

    const onMouseOut = () => {
        props.setCursorState((state) => state = '');
    };


    return (
        <div className='audio-switch'>
            <div onMouseOver={onMouseOver}
                 onMouseOut={onMouseOut} className='audio-switch-button' onClick={()=> {props.setPlayAudio((state)=>!state)}}><img src={props.playAudio ? "./images/sound_on.svg" : "./images/sound_off.svg" } alt=''/></div>
        </div>
    )
}