import './footer.css'
import {useEffect, useRef} from "react";
import gsap from 'gsap';
import SplitType from 'split-type'


export const Footer = (props) => {

    const description = useRef();


    useEffect(() => {
        const text = new SplitType(description.current, {
            types: "lines, words",
            lineClass: "word-line"
        });
        gsap.from(text.words, {
            y: "100%",
            skewX: "-6",
            opacity: 0,
            duration: 1.5,
            delay: 0.5,
            stagger: 0.03,
        });
    }, [])

    useEffect(() => {
        if (props.switchPage) {
            const text = new SplitType(description.current, {
                types: "lines, words",
                lineClass: "word-line"
            });
            gsap.to(text.words, {
                y: "100%",
                skewX: "-6",
                opacity: 0,
                duration: 1.5,
                stagger: 0.03,
            });
        }
    }, [props.switchPage])

    return (
        <div className='footer'>
            <div ref={description} className="footer__left-side">
                We help tech companies and brands<br/>
                deliver value and win users' attention
            </div>
        </div>
    )
}