import './loader.css';
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';



export default function Loading(props) {

  const [nextPercent, setNextPercent] = useState(['1','1']);
  const [currentPercent, setCurrentPercent] = useState(['0','0']);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const nextPercentRef = useRef();
  const percentsArray = useRef([]);
  const currentPercentRef = useRef();


  useEffect(() => {
      if (props.ready === 0) {
        return
      }
      let percent = props.ready;
      percentsArray.current.push(percent + Math.floor(Math.random() * 10));

  }, [props.ready]);


  useEffect(() => {
    let index = 0;

    const animatePercent = (percent) => {
      Array.from(percent.children).forEach((letter, index) => {
        gsap.fromTo(letter, { top: '1.1em' }, { top: '0', duration: 0.75, delay: index / 5 + 0.1 });
      });
    };

    const hidePercent = (percent) => {
      Array.from(percent.children).forEach((letter, index) => {
        gsap.fromTo(letter, { top: '0' }, { top: '-1.1em', duration: 0.75, delay: index / 5 });
      });
    };

    const updatePercent = (splitter) => {
      setCurrentPercent(splitter);
      setTimeout(() => {
        checkNewPercentToAnimate();
      }, 100);
    };

    const checkNewPercentToAnimate = () => {
      if (percentsArray.current[index]) {
        if (percentsArray.current[percentsArray.current.length - 1] >= 100) {
          setIsDataLoad(true);
          return;
        }
        const splitter = percentsArray.current[index].toString().split('');
        setNextPercent(splitter);

        if (!nextPercentRef.current) {
          return;
        }

        animatePercent(nextPercentRef.current);
        hidePercent(currentPercentRef.current);

        index += 1;
        setTimeout(() => {
          updatePercent(splitter);
        }, 1100);
      } else {
        setTimeout(() => {
          checkNewPercentToAnimate();
        }, 100);
      }
    };

    setTimeout(() => {
      checkNewPercentToAnimate();
    }, 1500);
  }, []);


  const launch = (isSoundOn) => {
    props.setClicked(true);
    props.setPlayAudio(isSoundOn);
  };

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };

  return (
    <div className='container__loading'>
      {isDataLoad ? <div className='launch_buttons'>
          <div onMouseOver={onMouseOver}
               onMouseOut={onMouseOut} onClick={() => {
            onMouseOut();
            launch(true);
          }} className='launch_button'><img src='./images/sound_on.svg' alt='' />LAUNCH WITH SOUND
          </div>
          <div className='line' />
          <div onMouseOver={onMouseOver}
               onMouseOut={onMouseOut} onClick={() => {
            onMouseOut();
            launch(false);
          }} className='launch_button off_sound'><img src='./images/sound_off.svg' alt='' />LAUNCH WITHOUT SOUND
          </div>
        </div> :
        <div className='loading_bar_container'>

          <div className='percents'>
            <div className='percents-numbers-container'>
              <span ref={nextPercentRef} className='percents-numbers next-percents'>{nextPercent.map((letter, index)=> <div key={index} className={'letter letter' + index}>{letter}</div>)}</span>
              <span ref={currentPercentRef} className='percents-numbers current-percents'>{currentPercent.map((letter, index)=> <div key={index} className={'letter letter' + index}>{letter}</div>)}</span>
            </div>
            <span className='percent-symbol'>%</span>
          </div>

          {/*<div className='percents'>{props.ready}%</div>*/}
          <div>LOADING</div>
        </div>}
    </div>
  );
}
