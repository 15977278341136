export const WORKSCONST = [
    {
        name: 'Altra',
        pathName: 'altra',
        description: 'Interactive explainers',
        bgColor: '#4b8d64',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoUrl: './videos/altra_bg',
        videoWorksUrl: './videos/altra_works',
        worksTitle: '3D TOKENIZED CITY IMMERSIVE EXPERIENCE',
        servicesList: 'Creative Design / Web Design / WebGL Development'
    },
    {
        name: 'Byte Trading',
        pathName: 'byte',
        description: 'Revolutionary 3D product landing page',
        bgColor: '#767676',
        tagline: 'GROW BRAND AWARENESS ',
        videoUrl: './videos/byte_bg',
        videoWorksUrl: './videos/byte_works',
        worksTitle: 'Technology company interactive explainer',
        servicesList: 'Creative production / WebGL Development'
    },
    {
        name: '3D city',
        pathName: 'city',
        description: '3D interactive landing',
        bgColor: '#688b66',
        tagline: 'BOOST USER ENGAGEMENT',
        videoUrl: './videos/city_bg',
        videoWorksUrl: './videos/city_works',
        worksTitle: '3D web design and development studio',
        servicesList: 'Creative Design / Web Design / WebGL Development'
    },
    {
        name: 'Corvus Research',
        pathName: 'corvus',
        description: '3D interactive landing page',
        bgColor: '#767676',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoUrl: './videos/corvus_bg',
        videoWorksUrl: './videos/corvus_works',
        worksTitle: 'Technology company interactive landing page',
        servicesList: 'Creative Design / Web Design / WebGL Development'
    },
    {
        name: 'Headphones',
        pathName: 'headphones',
        description: '3D headphones',
        bgColor: '#666666',
        tagline: 'GROW BRAND AWARENESS',
        videoUrl: './videos/headphones_bg',
        videoWorksUrl: './videos/headphones_works',
        worksTitle: 'Product interactive landing page',
        servicesList: 'Creative Design / Web Design / WebGL Development'
    }
]
