import './works.css';
import React, { useEffect, useRef, useState } from 'react';
import { WORKSCONST } from '../../constants/works.constant';
import gsap from 'gsap';
import SplitType from 'split-type';
import { useNavigate } from 'react-router';
import { Route, Routes, useLocation } from 'react-router-dom';
import CasesUI from '../Cases/CasesUi';


export default function WorksUI(props) {
  const navigate = useNavigate();

  const workTitle = useRef();
  const worksTags = useRef();
  const description = useRef();
  const worksUIContainer = useRef();
  const worksScrollbar = useRef();
  const caseButton = useRef();
  const servicesTitle = useRef();
  const DELTA_SCROLL_FOR_SWICH_PAGE = 150;
  const [deltaScroll, setDeltaScroll] = useState(0);
  const [toushY, setToushY] = useState(0);
  const [scrollDelay, setScrollDelay] = useState(true);

  useEffect(() => {
    if (!props.showCases) {
      animateText();
    }
  }, [props.workIndex, props.showCases]);


  const animateText = () => {

    gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.5 });
    // gsap.to(worksScrollbar.current, {opacity: 0, duration: 0.5})

    const title = new SplitType(workTitle.current, {
      types: 'words, chars',
      lineClass: 'horizontal-anim'
    });
    gsap.from(title.chars, {
      opacity: 0,
      duration: 1.0,
      stagger: 0.06
    }).then(() => {
      title.revert();
    });

    const textDescription = new SplitType(description.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.from(textDescription.words, {
      y: '100%',
      skewX: '-6',
      opacity: 0,
      duration: 1.5,
      stagger: 0.03
    }).then(() => {
      textDescription.revert();
    });

    const servicesT = new SplitType(servicesTitle.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });

    gsap.from(servicesT.words, {
      y: '100%',
      skewX: '-6',
      opacity: 0,
      duration: 1.5,
      stagger: 0.03
    }).then(() => {
      servicesT.revert();
    });

    const worksTagsS = new SplitType(worksTags.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.from(worksTagsS.words, {
      y: '100%',
      skewX: '-6',
      opacity: 0,
      duration: 1.5,
      stagger: 0.03
    }).then(() => {
      worksTagsS.revert();
    });
  };


  const animateTextFadeOut = () => {
    // servicesList.current = servicesList.current.slice(0, WORKSCONST[props.workIndex].servicesList.length);
    const title = new SplitType(workTitle.current, {
      types: 'words, chars',
      lineClass: 'horizontal-anim'
    });

    gsap.to(title.chars, {
      opacity: 0,
      duration: 0.5,
      stagger: 0.06
    });

    const textDescription = new SplitType(description.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.to(textDescription.words, {
      y: '100%',
      skewX: '-6',
      // opacity: 0,
      duration: 1,
      stagger: 0.03
    });

    const servicesT = new SplitType(servicesTitle.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.to(servicesT.words, {
      y: '100%',
      skewX: '-6',
      // opacity: 0,
      duration: 1,
      stagger: 0.03
    });

    const worksTagsS = new SplitType(worksTags.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.to(worksTagsS.words, {
      y: '100%',
      skewX: '-6',
      // opacity: 0,
      duration: 1,
      stagger: 0.03
    });
  };

  useEffect(() => {
    if (!props.showCases) {
      document.addEventListener('wheel', onWheel);
      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchend', onTouchEnd);
    }
    return () => {
      document.removeEventListener('wheel', onWheel);
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchend', onTouchEnd);
    };
  }, [deltaScroll, toushY, props.workIndex, scrollDelay, props.showCases]);

  useEffect(() => {
    if (props.switchPage) {
      gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.5 });
      gsap.to(worksScrollbar.current, { opacity: 0, duration: 0.5 });
    }
  }, [props.switchPage]);

  const onTouchStart = (e) => {
    setToushY(e.touches[0].clientY);
  };

  const onTouchEnd = (e) => {
    onWheel({ deltaY: toushY - e.changedTouches[0].clientY });
  };

  useEffect(() => {
    setTimeout(() => {
      setScrollDelay(false);
    }, 1550);
    if (!props.showCases) {
      gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.5 });
    }
  }, []);

  const onWheel = (e) => {
    if (!scrollDelay) {
      setDeltaScroll(deltaScroll + e.deltaY);
    }
    if (deltaScroll + e.deltaY >= DELTA_SCROLL_FOR_SWICH_PAGE && !scrollDelay) {
      if (props.workIndex < WORKSCONST.length - 1) {
        switchWork(props.workIndex + 1);
      } else {
        switchWork(0);
      }
      setDeltaScroll(0);
    }

    if (deltaScroll + e.deltaY <= -DELTA_SCROLL_FOR_SWICH_PAGE && !scrollDelay) {
      if (props.workIndex > 0) {
        switchWork(props.workIndex - 1);
      } else {
        switchWork(WORKSCONST.length - 1);
      }
      setDeltaScroll(0);
    }
  };

  const setWorkByClick = (index) => {
    if (!scrollDelay) {
      switchWork(index);
    }
  };

  const switchWork = (workIndex) => {
    setScrollDelay(true);
    gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.3 }).then(() => {
      props.setWorkIndex(workIndex);
      gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.1 });
      setTimeout(() => {
        setScrollDelay(false);
      }, 1550);
    });
  };

  const goToCase = () => {
    if (!scrollDelay) {
      setScrollDelay(true);
      animateTextFadeOut();
      gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.5 });
      gsap.to(worksScrollbar.current, { opacity: 0, duration: 0.5 });
      props.setCursorState((state) => state = '');
      setTimeout(() => {
        props.setShowCases(true);
        setScrollDelay(false);
        navigate('/works' + '/' + WORKSCONST[props.workIndex].pathName);
        // document.removeEventListener("wheel", onWheel);
        // document.removeEventListener('touchstart', onTouchStart);
        // document.removeEventListener('touchend', onTouchEnd);
      }, 500);
    }
  };

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };


  return (
    <>
      {props.showCases ? null : <>
        <div ref={worksUIContainer} className='works_container'>
          <div className='works_info_container'>
            <h1 ref={workTitle} className='works_title'>{WORKSCONST[props.workIndex].name}</h1>
            <div className='works_info'>
              <h2 ref={description} className='works_description'>
                {WORKSCONST[props.workIndex].worksTitle}
              </h2>
              <button onMouseOver={onMouseOver}
                      onMouseOut={onMouseOut} className='case_button' ref={caseButton} onClick={() => {
                goToCase();
              }}>SEE CASE<img className='case_button_arrow' src='/images/arrow-right.svg' alt='' />
              </button>
            </div>
          </div>
            <div className='works_tags_container'>
                <div ref={servicesTitle} className='works_tags_title'>SERVICES:</div>
                <div ref={worksTags} className='works_tags'>{WORKSCONST[props.workIndex].servicesList}
                </div>
            </div>
        </div>
        <div ref={worksScrollbar} className='scrollbar_container'>
          {WORKSCONST.map((item, index) => <div key={index} onClick={() => {
            setWorkByClick(index);
          }} className='scrollbar_dot'>
            <div className='static' />
            <div className={props.workIndex === index ? 'active resized' : 'resized'} />
          </div>)}
        </div>
      </>}
      {/*<Routes>*/}
      {/*    {WORKSCONST.map((item, index) => <Route key={index} path={'/' + item.pathName}*/}
      {/*                                            element={*/}
      {/*                                                <CasesUI*/}
      {/*                                                    backgroundRef={props.backgroundRef}*/}
      {/*                                                    mediaQuery={props.mediaQuery}*/}
      {/*                                                    casesImages={props.casesImages}*/}
      {/*                                                    setShowCases={props.setShowCases}*/}
      {/*                                                    videoUrls={props.videoUrls} workIndex={index}*/}
      {/*                                                />}*/}
      {/*    />)}*/}
      {/*</Routes>*/}
    </>

  );
}