import './contact.css'
import React, {useEffect, useRef} from "react";
import gsap from 'gsap';
import { useForm, ValidationError } from '@formspree/react';

const ContactForm = (props) => {

    const [state, handleSubmit] = useForm("mlezlzyg");
    if (state.succeeded) {
        return <h3 className='contact_form_title'>Thank you!</h3>;
    }

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };

    return (
      <form onSubmit={handleSubmit} className='contact_form'>
          <h3 className='contact_form_title'>Drop Us A Line</h3>
          <input onMouseOver={onMouseOver}
                 onMouseOut={onMouseOut} id="name" type="text" name="name" placeholder='Name'/>
          <input onMouseOver={onMouseOver}
                 onMouseOut={onMouseOut} id="email" type="email" name="email" placeholder='Email'/>
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
          />
          <input onMouseOver={onMouseOver}
                 onMouseOut={onMouseOut} id="text" type="text" name="text" placeholder='Details'/>
          <button onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut} className='submit_button' type="submit" disabled={state.submitting}>SUBMIT</button>
      </form>
    )
}


export const Contact = (props) => {

    const contact = useRef();


    useEffect(() => {
        gsap.fromTo(contact.current, {
            opacity: 0,
        }, {
            opacity: 1,
          delay:0.5,
            duration: 1.5,
        });
    }, [])

    useEffect(()=> {


        if (props.switchPage) {
            gsap.fromTo(contact.current, {
                opacity: 1,
            }, {
                opacity: 0,
                duration: 1.5,
            });
        }
    }, [props.switchPage])

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };

    return (
        <div ref={contact} className='contact'>
            <div className='contact_info'>
                <h1 className='contact_info_title'>Contact</h1>
                <div className='contact_info_name'>Third Dimension Studio LLC</div>
                <div className='contact_info_address'>1 Bill Court Palm Coast,<br/>
                    FL 32137
                </div>
                <a onMouseOver={onMouseOver}
                   onMouseOut={onMouseOut} className="contact_email" href="mailto: v@third-dimension.studio">v@third-dimension.studio</a>
                <a className='link_to link--kale' href='https://calendly.com/v-3d-dimension/30min?month=2022-11'
                   onMouseOver={onMouseOver}
                   onMouseOut={onMouseOut}
                   target='_blank'>BOOK A CALL →</a>
            </div>
            <ContactForm setCursorState={props.setCursorState}/>
        </div>
    )
}