export const CASESCONST = [
    {
        name: 'Altra',
        textInfo: ['The goal of the project is to tokenize real estate property amongst other goods such as forests or wind turbines.', 'Users will be able to invest in a wide variety of products with a relatively small entry fee. The company will offer opportunities in markets that would otherwise be inaccessible.'],
        aboutClientInfo: ['CoinsPaid is an organisation providing cryptocurrency payment services and personal wallets. The company enables customers to operate worldwide, decrease costs and reach new markets whilst using their reliable cryptocurrency processing services and the wallet app.'],
        quote: '',
        videoUrl: null,
        worksTitle: '3D tokenized city immersive experience.',
        link: 'https://altra.third-dimension.studio/',
        casesImages: [
            './images/cases/altra/1',
            './images/cases/altra/2',
            './images/cases/altra/3',
            './images/cases/altra/4',
            './images/cases/altra/5',
            './images/cases/altra/6',
            './images/cases/altra/7',
            './images/cases/altra/8',
            './images/cases/altra/9',
            './images/cases/altra/10',
            './images/cases/altra/11']
    },
    {
        name: 'Byte Trading',
        textInfo: ['In cooperation with DAAAMN creative production studio, we were engaged to creation of 3D interactive explainer for tech company.', 'Our team were needed to provide stable nice experience among devices and provide high speed of load.'],
        aboutClientInfo: ['Byte Trading is a technology company building a derivatives native technology stack for better pricing and risk management of positions.'],
        quote: '“We build high-speed risk management systems that seamlessly deal with any market environment.”',
        videoUrl: null,
        link: 'https://bytetrading.third-dimension.studio',
        casesImages: [
            './images/cases/byte/1',
            './images/cases/byte/2',
            './images/cases/byte/3',
            './images/cases/byte/4',
            './images/cases/byte/5',
            './images/cases/byte/6',
            './images/cases/byte/7',
            './images/cases/byte/8']
    },
    {
        name: '3D city',
        textInfo: ['This website was developed for 3D production studio. The goal was to show the services in an immersive way. The concept is based on different districts with idea of clients segments.'],
        aboutClientInfo: ['Third Dimension studio is a web design studio that provides tech companies and brands around the world with interactive web experiences and immersive explainers.'],
        quote: '',
        videoUrl: null,
        link: 'https://city.third-dimension.studio',
        casesImages: [
            './images/cases/city/1',
            './images/cases/city/2',
            './images/cases/city/3',
            './images/cases/city/4',
            './images/cases/city/5',
            './images/cases/city/6',
            './images/cases/city/7',
            './images/cases/city/8',
            './images/cases/city/9',
            './images/cases/city/10']

    },
    {
        name: 'Corvus Research',
        textInfo: ['The team wanted to launch the company website in the format of an interactive scene. The design was supposed to be related to mathematics and computing.', 'The main goal was to attract new engineers and provide detailed information about tech.'],
        aboutClientInfo: ['Corvus Research is a technology company developing cutting-edge statistical models, alternative data sources, and machine learning for quantitative finance.', 'Their mission is to build a fully automated machine for finding predictive signals in a variety of markets.'],
        quote: '',
        videoUrl: './videos/corvus_bg',
        link: 'https://corvus.third-dimension.studio/',
        casesImages: [
            './images/cases/corvus/1',
            './images/cases/corvus/2',
            './images/cases/corvus/3',
            './images/cases/corvus/4',
            './images/cases/corvus/5',
            './images/cases/corvus/6',
            './images/cases/corvus/7',
            './images/cases/corvus/8',
            './images/cases/corvus/9']
    },
    {
        name: 'Headphones',
        textInfo: ['This demo case is purposed to show all posibilities of WebGL tech to create 3D interactive landing pages.', 'Here we shown all features of the device from the best angles to achieve maximum of engagement.'],
        aboutClientInfo: ['Third Dimension studio is a web design studio that provides tech companies and brands around the world with interactive web experiences and immersive explainers.'],
        quote: '',
        videoUrl: null,
        link: 'https://headphones.third-dimension.studio/',
        casesImages: [
            './images/cases/headphones/1',
            './images/cases/headphones/2',
            './images/cases/headphones/3',
            './images/cases/headphones/4',
            './images/cases/headphones/5',
            './images/cases/headphones/6',
            './images/cases/headphones/7',
            './images/cases/headphones/8']
    }
]
