import App from './App'
import './styles.css'
import ReactDOM from "react-dom/client";
import {BrowserRouter, HashRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.querySelector('#root'))


root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
)
