import { Footer } from '../Footer/Footer'
import { Contact } from '../Contact/Contact'

import { Header } from '../Header/Header'
import { Routes, Route } from 'react-router-dom'
import { ROUTES } from '../../constants/routes.constants'
import WorksUI from "../Works/WorksUI";
import {AudioSwitch} from "../AudioSwitch/AudioSwitch";
import {WORKSCONST} from "../../constants/works.constant";
import CasesUI from "../Cases/CasesUi";
import React from "react";

export default function Html(props) {
  return (
    <>
      <Header setCursorState={props.setCursorState} switchPage={props.switchPage} mainTextTransition={props.mainTextTransition} clicked={props.clicked} setSwitchPage={props.setSwitchPage} setShowCases={props.setShowCases}/>
        <Routes>
            <Route path={ROUTES.Works} element={<WorksUI setCursorState={props.setCursorState} videoUrls={props.videoUrls} showCases={props.showCases} switchPage={props.switchPage} setShowCases={props.setShowCases} workIndex={props.workIndex} setWorkIndex={props.setWorkIndex}/>}/>
            {WORKSCONST.map((item, index) => <Route key={index} path={'/works/' + item.pathName}
                                                    element={
                                                        <CasesUI
                                                            backgroundRef={props.backgroundRef}
                                                            mediaQuery={props.mediaQuery}
                                                            setCursorState={props.setCursorState}
                                                            transition={props.transition}
                                                            setTransition={props.setTransition}
                                                            switchPage={props.switchPage}
                                                            casesImages={props.casesImages}
                                                            setShowCases={props.setShowCases}
                                                            videoUrls={props.videoUrls} workIndex={index}
                                                        />}
            />)}
            <Route path={ROUTES.Main} element={props.clicked ? <Footer switchPage={props.switchPage} /> : null}/>
            <Route path='/contact' element={props.clicked ? <Contact setCursorState={props.setCursorState} switchPage={props.switchPage}/> : null}/>
        </Routes>
        <AudioSwitch setCursorState={props.setCursorState} audio={props.audio} playAudio={props.playAudio} setPlayAudio={props.setPlayAudio}/>
    </>
  )
}
