import {useEffect, useMemo, useRef, useState} from "react";
import {Text, useTexture, useVideoTexture, CubeCamera} from "@react-three/drei";
import * as THREE from "three";
import {WORKSCONST} from "../../constants/works.constant";
import gsap from 'gsap';
import Power3 from 'gsap';
import {useThree} from "@react-three/fiber";
import * as PropTypes from "prop-types";
import { useNavigate } from 'react-router';




export default function MainVideoText({clicked, ...props}) {

    const [video] = useState(() => Object.assign(document.createElement('video'), {
        src: props.videoUrls[props.workIndex],
        crossOrigin: 'Anonymous',
        playsInline: true,
        loop: true,
        muted: true
    }))
    const alphaTexture = useTexture('./alphaTextLarge.jpg');
    const [taglineText, setTaglineText] = useState(WORKSCONST[props.workIndex].tagline);
    const [sizeConst] = useState(6.52);
    const allText = useRef();
    const textMain = useRef();
    const textShadow = useRef();
    const textReflection = useRef();
    const tagline = useRef();
    const navigate = useNavigate();
    const taglineShadow = useRef();
    const {width} = useThree((state) => state.viewport)


    useEffect(() => {
        props.setShowCases(false);
        props.setMainTextTransition(true);
        video.play();
        gsap.to(allText.current.position, {x: 0, z: 5, duration: 1.5, delay: 0.5, ease: Power3.easeInOut})
        gsap.to(textMain.current, {fillOpacity: 1, duration: 2, delay: 0.5, ease: Power3.easeInOut})
        gsap.to(textShadow.current, {fillOpacity: 0.4, duration: 2, delay: 0.5, ease: Power3.easeInOut})
        gsap.to(textReflection.current, {fillOpacity: 0.4, duration: 2,delay: 0.5, ease: Power3.easeInOut})
        gsap.to(tagline.current, {fillOpacity: 1, duration: 2, delay: 0.5, ease: Power3.easeInOut})
        gsap.to(taglineShadow.current, {fillOpacity: 0.6, duration: 2, delay: 0.5, ease: Power3.easeInOut}).then(() => {
            props.setMainTextTransition(false);
        })

        let currentIndex = 0;
        const delay = 4000;
        let interval = setTimeout(
            function tick() {
                if (props.goToWorks) {
                    return
                }
                currentIndex = (WORKSCONST.length - 1) === currentIndex ? 0 : currentIndex + 1;
                gsap.fromTo(tagline.current.rotation, {x: 0}, {x: 0.5 * Math.PI, duration: 1}).then(() => {
                    setTaglineText(WORKSCONST[currentIndex].tagline);
                    if (tagline) {
                        tagline.current.rotation.x += Math.PI;
                        gsap.fromTo(tagline.current.rotation, {x: 1.5 * Math.PI}, {x: 2 * Math.PI, duration: 1})
                    }
                })
                gsap.fromTo(taglineShadow.current.rotation, {x: 0}, {x: 0.5 * Math.PI, duration: 1}).then(() => {
                    if (taglineShadow) {
                        taglineShadow.current.rotation.x += Math.PI;
                        gsap.fromTo(taglineShadow.current.rotation, {x: 1.5 * Math.PI}, {x: 2 * Math.PI, duration: 1})
                    }
                })
                video.src = props.videoUrls[currentIndex];
                video.play();
                props.setWorkIndex(currentIndex);
                interval = setTimeout(tick, delay);
            }
            , delay)
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (props.switchPage) {
            gsap.to(textMain.current, {fillOpacity: 0, duration: 0.7})
            gsap.to(textShadow.current, {fillOpacity: 0, duration: 0.7})
            gsap.to(textReflection.current, {fillOpacity: 0, duration: 0.7})
            gsap.to(tagline.current, {fillOpacity: 0, duration: 0.7})
            gsap.to(taglineShadow.current, {fillOpacity: 0, duration: 0.7})
        }
    }, [props.switchPage]);

    const onMouseOver = () => {
        if ( !props.switchPage) {
            props.setCursorState((state) => state = 'cursor-hover-link');
            document.body.style.cursor = 'pointer'
        }
    };

    const onMouseOut = () => {
        props.setCursorState((state) => state = '');
        document.body.style.cursor = 'default'
    };

    const goToWorks = () => {
        if (!props.mainTextTransition) {
            props.setSwitchPage(true);
            props.setCursorState((state) => state = '');
            document.body.style.cursor = 'default'
            setTimeout(() => {
                navigate('/works');
                props.setSwitchPage(false);
            }, 2000);
        }
    };

    return (
        <>
        <group ref={allText} position={[0.6, 0, 4]} rotation-y={-0.35}>
            <Text onClick={goToWorks} onPointerEnter={onMouseOver} onPointerOut={onMouseOut} ref={textMain} fillOpacity={0} font="/fonts/TTNormsPro-Bold.woff" lineHeight={0.9}
                  letterSpacing={-0.07} maxWidth={0.5}
                  fontSize={props.mediaQuery === 'phone' ? (width / sizeConst * 0.55) : (width / sizeConst * 0.5)}>
                THIRD DIMENSION
                <meshBasicMaterial transparent={true}>
                    <videoTexture encoding={THREE.sRGBEncoding} format={THREE.RGBAFormat} transparent={true}
                                  attach="map" args={[video]}/>
                </meshBasicMaterial>
            </Text>

            {/*shadow*/}
            <Text ref={textShadow} fillOpacity={0}
                  position={props.mediaQuery === 'phone' ? [width / sizeConst * 0.65, 0, width / sizeConst * -0.28] : [0, 0, width / sizeConst * -0.09]}
                  letterSpacing={-0.07}
                  font="/fonts/TTNormsPro-Bold.woff" lineHeight={0.9} maxWidth={0.5}
                  fontSize={props.mediaQuery === 'phone' ? (width / sizeConst * 0.85) : (width / sizeConst * 0.5)}>
                THIRD DIMENSION
                <meshBasicMaterial transparent={true} color="#575757">
                    <videoTexture encoding={THREE.sRGBEncoding} format={THREE.RGBAFormat} transparent={true}
                                  attach="map" args={[video]}/>
                </meshBasicMaterial>
            </Text>

            {/*reflection*/}

            <Text ref={textReflection} fillOpacity={0} position-z={width / sizeConst * 0.34} position-y={props.mediaQuery === 'phone' ?  width / sizeConst * -0.41 : width / sizeConst * -0.37} rotation-x={0.52 * Math.PI} font="/fonts/TTNormsPro-Bold.woff"
                  lineHeight={0.9} letterSpacing={-0.07} maxWidth={0.5}
                  fontSize={props.mediaQuery === 'phone' ? (width / sizeConst * 0.55) : (width / sizeConst * 0.5)}>
                THIRD DIMENSION
                <meshBasicMaterial alphaMap={alphaTexture}
                                   side={THREE.BackSide}>
                    <videoTexture  encoding={THREE.sRGBEncoding} format={THREE.RGBAFormat} transparent={true} attach="map" args={[video]}/>
                </meshBasicMaterial>
            </Text>

            {/*tagline*/}


            <Text ref={tagline} fillOpacity={0} font="/fonts/TTNormsPro-Regular.woff"
                  position={[width / sizeConst * 1.15, width / sizeConst * 0.14, 0]}
                  maxWidth={props.mediaQuery === 'phone' ? width / sizeConst * 0.35 : width / sizeConst * 0.4}
                  fontSize={props.mediaQuery === 'phone' ? (width / sizeConst * 0.045) : (width / sizeConst * 0.035)}>
                {taglineText}
                <meshBasicMaterial color="white" side={THREE.DoubleSide}>
                </meshBasicMaterial>
            </Text>

            {/*taglineShadow*/}

            <Text ref={taglineShadow} fillOpacity={0} font="/fonts/TTNormsPro-Regular.woff"
                  position={[width / sizeConst * 1.15, width / sizeConst * 0.14, width / sizeConst * -0.05]}
                  maxWidth={props.mediaQuery === 'phone' ? width / sizeConst * 0.35 : width / sizeConst * 0.4}
                  fontSize={props.mediaQuery === 'phone' ? (width / sizeConst * 0.045) : (width / sizeConst * 0.035)}>
                {taglineText}
                <meshBasicMaterial transparent={true} color="#575757" side={THREE.DoubleSide}>
                </meshBasicMaterial>
            </Text>
        </group>
    </>
    );
}